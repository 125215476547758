import {Box, Clickable, getCssSize, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useNavigation} from '../NavigationContext/NavigationContext';

interface NavigationProps {
  offset: number | null;
}

export function Navigation(props: NavigationProps) {
  const {sections, activeSection} = useNavigation();

  return (
    <Box position="sticky" top={`calc(${getCssSize(10)} + ${props.offset ?? 0}px)`}>
      <VStack spacing={6} width="100%">
        {sections.map((section, index) => {
          const isActive = index === activeSection;

          return (
            <Clickable
              key={section.title}
              onClick={() => {
                section.ref.current?.scrollIntoView({behavior: 'smooth'});
              }}
            >
              <StyledNavigationBox $isActive={isActive}>
                <Text alternative={isActive}>{section.title}</Text>
              </StyledNavigationBox>
            </Clickable>
          );
        })}
      </VStack>
    </Box>
  );
}

const StyledNavigationBox = styled.div<{$isActive: boolean}>`
  padding-left: ${(props) =>
    props.$isActive ? `calc(${props.theme.getSize(4)} - 2px)` : props.theme.getSize(4)};
  border-left: ${(props) =>
    props.$isActive ? `2px solid ${props.theme.colors.general.accent}` : 'none'};
`;
