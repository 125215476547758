import {
  Box,
  Display,
  Grid,
  Heading,
  Hide,
  HStack,
  Image,
  Show,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {Button} from '../../../../components/Button/Button';
import {A4_WIDTH} from '../../../../consts/a4Width';
import {PAGE_MAX_WIDTH} from '../../../../consts/pageMaxWidth';
import {useDownloadPDF} from '../../../../hooks/useDownloadPDF';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import i18n from '../../../../i18n/i18n';
import {featureFlags} from '../../../../utils/featureFlags';
import {HeaderItem} from './components/HeaderItem';
import {formatToParagraphs} from './utils/formatToParagraphs';
import {
  isTruncatedWithoutSplittingWords,
  truncateWithoutSplittingWords,
} from './utils/truncateWithoutSplittingWords';

interface VinDecoderHeaderProps extends TestIdProps {
  onMenuClick: () => void;
  onShareClick: () => void;
}

export function VinDecoderHeader(props: VinDecoderHeaderProps) {
  const {decoder, make, makeLogo, modelFamily} = useGetVinDecoderData();
  const [isDescriptionExpanded, toggleIsDescriptionExpanded] = useToggle(false);
  const {handleDownload, isPreparingDownload} = useDownloadPDF();
  const device = useDevice();
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';

  const description = decoder?.data?.modelDescription;

  return (
    <VStack spacing={10}>
      <Box backgroundColor="accent.primary">
        <VStack align="center">
          <Box
            width={isPrintForced ? A4_WIDTH : '100%'}
            maxWidth={PAGE_MAX_WIDTH}
            paddingHorizontal={isPrintForced ? 10 : [5, 10, 10, 25, 10]}
          >
            <Box paddingVertical={isPrintForced ? 6 : [5, 6]}>
              <HStack align="center" justify="space-between">
                <Image
                  src="./assets/images/logo.svg"
                  alt="VIN Decoder"
                  height={4}
                  width="auto"
                  data-testid={suffixTestId('logo', props)}
                />
                <Hide onMobile inPrinter when={isPrintForced}>
                  <HStack spacing={2}>
                    <Button
                      title={i18n.t('general.labels.share')}
                      variant="outline"
                      onClick={props.onShareClick}
                      data-testid={suffixTestId('share', props)}
                    />
                    <Show whenFeatureEnabled={featureFlags.V1N_DOWNLOAD_BUTTON}>
                      <Button
                        title={i18n.t('general.labels.download')}
                        variant="black"
                        onClick={handleDownload}
                        isLoading={isPreparingDownload}
                        data-testid={suffixTestId('download', props)}
                      />
                    </Show>
                  </HStack>
                </Hide>
                <Show onMobile when={!isPrintForced}>
                  <Button
                    title={i18n.t('general.labels.menu')}
                    variant="black"
                    size="small"
                    onClick={props.onMenuClick}
                    data-testid={suffixTestId('openMobileMenu', props)}
                  />
                </Show>
              </HStack>
            </Box>

            <Box
              paddingTop={isPrintForced ? 10 : [5, 10]}
              paddingBottom={isPrintForced ? 20 : [10, 20]}
            >
              <VStack spacing={10}>
                <VStack align="flex-start">
                  <Box
                    paddingHorizontal={4}
                    paddingVertical={2}
                    border="1px solid black"
                    borderRadius="small"
                  >
                    <Text size="xSmall" alternative>
                      {i18n.t('general.labels.vin')}: {decoder?.data?.vin ?? '?'}
                    </Text>
                  </Box>
                </VStack>

                <Grid columns={isPrintForced ? 2 : [1, 1, 1, 2]} spacing={10} align="flex-start">
                  <Grid columns={isPrintForced ? 1 : [1, 2, 2, 1]} spacing={10}>
                    <VStack align="flex-start" spacing={2}>
                      <Show when={isNotNil(makeLogo)}>
                        <Image
                          src={makeLogo ?? ''}
                          alt={make}
                          height={18}
                          width="auto"
                          data-testid={suffixTestId('makeLogo', props)}
                        />
                      </Show>
                      <Box>
                        <Display size={1}>{make ?? '?'}</Display>
                        <Display size={1} color="white">
                          {modelFamily ?? '?'}
                        </Display>
                      </Box>
                    </VStack>
                    <Hide inPrinter when={isNil(description) || isPrintForced}>
                      <VStack align="flex-start" spacing={6}>
                        <Heading size={2}>
                          {i18n.t('entity.header.labels.vehicleCharacteristic')}
                        </Heading>
                        <Text size="small">
                          {isDescriptionExpanded
                            ? formatToParagraphs(description)
                            : formatToParagraphs(truncateWithoutSplittingWords(description))}
                        </Text>
                        <Show when={isTruncatedWithoutSplittingWords(description)}>
                          <Button
                            title={i18n.t(
                              `entity.header.labels.${
                                isDescriptionExpanded
                                  ? 'hideCompleteDescription'
                                  : 'showCompleteDescription'
                              }`
                            )}
                            variant="black"
                            onClick={toggleIsDescriptionExpanded}
                            data-testid={suffixTestId('toggleDescription', props)}
                          />
                        </Show>
                      </VStack>
                    </Hide>
                  </Grid>
                  <Grid columns={isPrintForced ? 2 : [2, 3, 3, 2]} spacing={10}>
                    <HeaderItem
                      icon="year"
                      title={i18n.t('entity.vehicle.labels.yearManufactured')}
                      value={decoder?.data?.manufacturedOnYear}
                    />
                    <HeaderItem
                      icon="vehicleType"
                      title={i18n.t('entity.vehicle.labels.vehicleType')}
                      value={decoder?.data?.vehicleType}
                      name="vehicle_type"
                    />
                    <HeaderItem
                      icon="transmission"
                      title={i18n.t('entity.vehicle.labels.transmission')}
                      value={decoder?.data?.transmission}
                      name="transmission"
                    />
                    <HeaderItem
                      icon="drive"
                      title={i18n.t('entity.vehicle.labels.drive')}
                      value={decoder?.data?.drive}
                      name="drive"
                    />
                    <HeaderItem
                      icon="fuelType"
                      title={i18n.t('entity.vehicle.labels.fuelType')}
                      value={decoder?.data?.fuelType}
                      name="fuel_type"
                    />
                    <HeaderItem
                      icon="power"
                      title={i18n.t('entity.vehicle.labels.power')}
                      value={decoder?.data?.power}
                      unit="kW"
                    />
                    <HeaderItem
                      icon="emissionClass"
                      title={i18n.t('entity.vehicle.labels.emissionClass')}
                      value={decoder?.data?.emissionClass}
                      name="emission_class"
                    />
                    <HeaderItem
                      icon="carbonDioxideEmission"
                      title={i18n.t('entity.vehicle.labels.carbonDioxideEmission')}
                      value={decoder?.data?.carbonDioxideEmission}
                      unit="g/km"
                    />
                  </Grid>
                </Grid>
              </VStack>
            </Box>
          </Box>
        </VStack>
      </Box>
      <Hide when={isNil(description) || !(isPrintForced || device === 'printer')}>
        <VStack align="center">
          <Box
            width={isPrintForced ? A4_WIDTH : '100%'}
            maxWidth={PAGE_MAX_WIDTH}
            paddingHorizontal={10}
          >
            <Text size="small" align="justify">
              {formatToParagraphs(description)}
            </Text>
          </Box>
        </VStack>
      </Hide>
    </VStack>
  );
}
